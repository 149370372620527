/* =================
   Typehead
==================== */

.twitter-typeahead {
  display: inherit !important;
}

.tt-query,
.tt-hint {
  padding: 8px 12px;
  line-height: 30px;
  outline: none;
}

.tt-query {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: $text-muted;
}

.tt-menu {
  width: 100%;
  padding: 8px 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: $card-bg;
  box-shadow: 0 1px 3px rgba($black,0.1);
  border: 1px solid $border-color;
}

.tt-suggestion {
  padding: 3px 20px;
  line-height: 24px;
  &:hover {
    cursor: pointer;
    color: $white;
    background-color: $primary;
  }
  &.tt-cursor {
    color: $white;
    background-color: $primary;
  }

  p {
    margin: 0;
  }
  
}

.tt-suggestion
.tt-highlight {
  font-family: $font-family-secondary;
}

.tt-suggestion 
.typeahead-empty-message {
  padding: 5px 10px;
  color: $danger;
}

.league-name {
  padding: 3px 20px;
}
