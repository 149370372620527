/* =============
   Widgets
============= */
.widget-box-one {
  .widget-one-icon {
    position: absolute;
    right: 30px;
    font-size: 72px !important;
    top: 0;
    color: $gray-300;
    overflow: hidden;
    vertical-align: middle;
    line-height: 2 !important;
  }

  .wigdet-one-content {
    position: relative;
  }
}


//    Widgets-2


.widget-box-two {
  text-align: right;
  
  .wigdet-two-content {
    position: relative;
    overflow: hidden;
  }
}

.widget-two-default {
  background-color: $white;
  .widget-two-icon {
    color: darken($gray-300,20%);
    border: 2px solid darken($gray-300,20%);
  }
}




//    Widgets-3

.widget-box-three {
  .bg-icon {
    border: 1px dashed $text-muted;
    background-color: $gray-300;
    line-height: 68px;
    text-align: center;
  }
}


.widget-user {
  position: relative;
}


.widget-box-four {
  position: relative;

  .widget-box-four-chart {
    position: absolute;
    right: 20px;
    bottom: 25px;
  }
  .wigdet-four-content {
    background-color: rgba($white,0.2);
    position: relative;
  }
  h3 {
    font-size: 22px;
  }
}


/* Inbox-widget */

.inbox-widget {
  .inbox-item {
    border-bottom: 1px solid rgba($gray-300,0.9);
    overflow: hidden;
    padding: 10px 0;
    position: relative;
    .inbox-item-img {
      display: block;
      float: left;
      margin-right: 15px;
      width: 40px;
    }
    img {
      width: 40px;
    }
    .inbox-item-author {
      color: $dark;
      display: block;
      margin: 0;
      font-family: $font-family-secondary;
    }
    .inbox-item-text {
      display: block;
      font-size: 12px;
      margin: 0;
      color: $text-muted;
    }
    .inbox-item-date {
      font-size: 11px;
      position: absolute;
      right: 7px;
      top: 2px;
    }
  }
}


.user-position {
  position: absolute;
  top: 0;
  border-left: 1px solid $light;
  bottom: 0;
  width: 44px;
  font-size: 16px;
  text-align: center;
  right: 0;
  left: auto;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  flex-direction: row;
  -webkit-flex-direction: row;

  span {
    transform: rotate(90deg);
  }
}


/* Avatar box */
.avatar-sm-box {
  height: 32px;
  width: 32px;
  color: $white;
  display: block;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  font-family: $font-family-secondary;
  font-size: 16px;
}

/* Table with Action */
.table-actions-bar {
  tr {
    td{
      vertical-align: middle !important;
    }
  }
  .table-action-btn {
    color: $text-muted;
    display: inline-block;
    width: 28px;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
    font-size: 20px;

    &:hover {
      color: $primary;
      border-color: $primary;
    }
  }
}