/* ===========
Slider
============== */


/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url('../fonts/slick.eot');
    src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'), url('../fonts/slick.woff') format('woff'), url('../fonts/slick.ttf') format('truetype'), url('../fonts/slick.svg#slick') format('svg');
}

/* Slider */
.slick-loading .slick-list
{
    background: #fff url('../images/plugins/ajax-loader.gif') center center no-repeat;
}



.slick-prev, .slick-next {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  z-index: 1;
  background-color: $dark !important;
  box-shadow: $box-shadow;
}

.slider-padding {
  div {
    margin: 5px;
  }
}

.slick-prev:before, .slick-next:before {
  color: $primary;
}

.slick-prev {
  left: 0;
}

.slick-next {
  right: 0;
}

.slick-dots li button::before {
  font-size: 10px;
  color: $dark !important;
  border: 2px solid $dark !important;
  border-radius: 50%;
  line-height: 18px;
}

.slick-center img {
  opacity: 1;
  transform: scale(1.01);
  border: 3px solid $primary;
}

.slider-syncing-nav {
  margin-top: 10px;

  div {
    border-left: 3px solid $white;
  }
}
