/* =============
   Task board
============= */

.taskList {
  min-height: 40px;
  margin-bottom: 0;

  li {
    background-color: $card-bg;
    border: 1px solid rgba($gray-700,0.2);
    padding: 10px;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
  a {
    font-size: 13px;
  }
  .checkbox {
    margin-left: 20px;
    margin-top: 5px;
  }
}

.task-placeholder {
  border: 1px dashed $gray-300 !important;
  background-color: lighten($gray-200, 2.5%) !important;
  padding: 20px;
}


/* Task Detail */
.task-detail {
  .task-dates {
    li {
      width: 50%;
      float: left;
    }
  }

  .task-tags {
    .bootstrap-tagsinput {
      padding: 0px;
      border: none;
    }
  }

  .assign-team {
    a {
      display: inline-block;
      margin: 5px 5px 5px 0px;
    }
  }

  .files-list {
    .file-box {
      display: inline-block;
      vertical-align: middle;
      width: 80px;
      padding: 2px;
      border-radius: 3px;
      background-clip: padding-box;
      img {
        line-height: 70px;
      }
      p {
        width: 100%;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}