/* =============
   Profile
============= */

.profile-bg-picture {
  position: relative;
  min-height: 300px;
  margin: 0px -20px 0px -20px;
  background-position: center;
  background-size: cover;
}
.profile-bg-picture >.picture-bg-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgeG1sbnM9Imh0dâ€¦0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2xlc3NoYXQtZ2VuZXJhdGVkKSIgLz48L3N2Zz4=);
  background-image: linear-gradient(to bottom,rgba(255,255,255,0) 0,rgba(0,0,0,.4) 100%);
}

.profile-user-box {
  background-color: $card-bg;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: $box-shadow;
  margin-top: -65px;
  position: relative;
  border-radius: 5px;
}