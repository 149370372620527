/* =============
   Account Pages
============= */

.text-error {
  color: $primary;
  text-shadow: rgba($primary,0.3) 5px 1px, rgba($primary,0.2) 10px 3px;
  font-size: 84px;
  font-weight: 700;
  line-height: 90px;
}

