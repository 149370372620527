// 
// components-demo.scss
//

// Demo Only
.button-list {
    margin-left: -8px;
    margin-bottom: -12px;
    
    .btn {
        margin-bottom: 12px;
        margin-left: 8px;
    }
}


// Icon demo ( Demo only )
.icon-list-demo {
    div {
        cursor: pointer;
        line-height: 45px;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        p {
            margin-bottom: 0;
            line-height: inherit;
        }
    }
    i {
        text-align: center;
        vertical-align: middle;
        font-size: 22px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        margin-right: 12px;
        color: rgba($dark, 0.7);
        border-radius: 3px;
        display: inline-block;
        transition: all 0.2s;
    }
    [class^="pe-7s-"], [class*=" pe-7s-"] {
        font-weight: 600;
      }
    .col-lg-4 {
        background-clip: padding-box;
        margin-top: 10px;
        &:hover i {
            color: $white;
            background: $primary;
        
        }
    }
}


// Grid

.grid-structure {
    .grid-container {
        background-color: $gray-100;
        margin-top: 10px;
        font-size: .8rem;
        font-weight: $font-weight-medium;
        padding: 10px 20px;
    }
}


/* Icon colored demo */
.icon-colored {
    height: 48px;
    width: 48px;
    margin: 1.4rem;
  }
  
  .icon-colored-lg {
    height: 64px;
    width: 64px;
    margin: 2.2rem;
  }

  // Demos button
.demos-show-btn {
    position: fixed;
    top: 50%;
    right: 0;
    writing-mode: vertical-rl;
    font-weight: 600;
    background-color: $danger;
    color: $white !important;
    line-height: 36px;
    padding: 15px 3px;
    border-radius: 6px 0 0 6px;
    transform: translateY(-50%);
    text-transform: uppercase;
}

@media (max-width: 600px) {
    .demos-show-btn {
        display: none;
    }
}