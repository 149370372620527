// 
// tablesaw.scss
//

.tablesaw {
    thead {
        background: lighten($gray-200, 2%);
        background-image: none;
        border: none;
        th {
            text-shadow: none;
        }
        tr:first-child th {
            border: none;
            font-weight: $font-weight-medium;
            font-family: $font-family-secondary;
        }
    }
    td {
        border-top: 1px solid lighten($gray-200, 2%) !important;
    }

    td, tbody th {
        font-size: inherit;
        line-height: inherit;
        padding: 10px !important;
    }
}


.tablesaw-stack, .tablesaw  {
    tbody {
        tr {
            border-bottom: none;
        }
    }
}

.tablesaw-bar{
    .btn-select{
        .btn-small, .btn-micro{
            &:after{
                font-size: 8px;
                padding-right: 10px;
            }
        }
    }
}

.tablesaw-swipe {
    .tablesaw-cell-persist {
        box-shadow: none;
        border-color: $gray-100;
    }
    .tablesaw-swipe-cellpersist {
        border-right: 2px solid lighten($gray-200, 2%);
    }
}

.tablesaw-bar-section {
    label{
        color: $gray-600;
    }
}


.tablesaw-enhanced {
    .tablesaw-bar {
        .btn {
            text-shadow: none;
            background-image: none;
            text-transform: none;
            border: 1px solid $gray-300;
            padding: 3px 10px;
            color: $dark;
        
            &:after {
                display: none;
            }

            &.btn-select {
                &:hover {
                    background: $white;
                }
            }

            &:hover, &:focus, &:active{
                color: $primary !important;
                background-color: $gray-100;
                outline: none !important;
                box-shadow: none !important;
                background-image: none;
            }
        }
    }
}

.tablesaw-columntoggle-popup {
    .btn-group {
        display: block;
    }
}

.tablesaw-sortable-btn {
    cursor: pointer;
}

.tablesaw-swipe-cellpersist {
    width: auto !important;
}

.tablesaw-columntoggle-btnwrap.visible {
    .tablesaw-columntoggle-popup{
        border-color: $border-color;
        background-color: $card-bg;
        box-shadow: none;
    }
}