.capitalize {
  text-transform: capitalize;
}

table.jexcel {
  width: 100%;
}

.hide {
  display: none;
}

.hideloader {
  display: none;
}

.success-gradient {
  background: linear-gradient(135deg, #8BC34A, #4CAF50) !important;
}

.danger-gradient {
  background: linear-gradient(135deg, #F44336, #E91E63) !important;
}

#loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $green3;
  z-index: 99999;
}

#saving-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.sk-chase {
  width: 40px;
  height: 40px;
  position: absolute;
  animation: sk-chase 2.5s infinite linear both;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: sk-chase-dot 2.0s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-chase-dot-before 2.0s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2) {
  animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3) {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4) {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5) {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6) {
  animation-delay: -0.6s;
}

.sk-chase-dot:nth-child(1):before {
  animation-delay: -1.1s;
}

.sk-chase-dot:nth-child(2):before {
  animation-delay: -1.0s;
}

.sk-chase-dot:nth-child(3):before {
  animation-delay: -0.9s;
}

.sk-chase-dot:nth-child(4):before {
  animation-delay: -0.8s;
}

.sk-chase-dot:nth-child(5):before {
  animation-delay: -0.7s;
}

.sk-chase-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes sk-chase {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {

  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    transform: scale(0.4);
  }

  100%,
  0% {
    transform: scale(1.0);
  }
}

.table-responsive.bitacora {
  .form-inline {
    .btn {
      margin-left: 10px;
    }
    .form-group {
      width: 88%;

      input {
        width: 100%;
      }
    }
  }
}

@media (max-width:480px)  {
  
  .table-responsive.bitacora {
    .form-inline {
      .form-group, .btn {
        width: 100%;
      } 
      .btn {
        margin-left: 0;
      }
    }
  }
}
 

