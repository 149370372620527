/* =============
   Sitemaps
============= */

.sitemap > li > ul {
  margin-top: 1rem;
  padding-left: 0;
}

ul.sitemap {
  list-style: none;
  padding-left: 0;

  li {
    line-height: 1.5rem ;
    vertical-align: top ;
    list-style: none;
    position: relative ;

    a {
      text-decoration: none ;
      color: darken($text-muted,10%);
      display: inline-block ;

      &:hover {
        color: $primary;
      }
    }
  }


  ul {
    margin-left: 1rem ;
    margin-bottom: 1rem ;
    padding-top: 10px;
    padding-left: 20px;

    li {
      position: relative ;
      &::before {
        content: "" ;
        display: inline-block ;
        width: 1rem ;
        height: 100% ;
        border-left: 1px solid rgba($text-muted,0.5) ;
        position: absolute;
      }

      &::before {
        content: "" ;
        display: inline-block ;
        width: 1rem;
        height: 1.5rem ;
        border-bottom: 1px $gray-300 solid ;
        position: absolute ;
        top: -0.75rem;
      }
      a {
        margin-left: 1.5rem;
      }
    }
  }
}

ul.sitemap-icon {
  ul li a {
    margin-left: 1.5rem;
  }
}